import React from 'react';
import flower from './flower.jpg';
import './App.css';

function App() {
  return (
    <div className="App">
      <h2>Florew rise - A WORK IN PROGRESS !!!</h2>
      <img id="flower" src={flower} alt="flower" />
    </div>
  );
}

export default App;
